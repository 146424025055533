<template>
  <div>

    <b-row>
      <b-col md="9">
        <b-form-group
          :label="$t('Outra')"
        >
          <b-input-group
            v-if="view===false"
            class="input-group-merge"
          >
            <b-input-group-prepend is-text>
              <feather-icon
                size="16"
                icon="GlobeIcon"
              />
            </b-input-group-prepend>
            <b-form-input
              v-model="form_sw095[index].sw095s06"
              type="url"
              autocomplete="off"
            />
          </b-input-group>
          <p
            v-else
            class="text-primary mb-0"
          >
            <feather-icon
              size="16"
              icon="GlobeIcon"
              class="mr-1"
            />

            {{ form_sw095[index].sw095s06 || '-----' }}
          </p>
        </b-form-group>
      </b-col>
      <b-col
        v-if="view===false"
        md="3"
      >
        <div
          class="d-none d-sm-block"
          style="margin-top: 1.95rem"
        />
        <b-button
          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
          variant="outline-danger"
          @click.stop.prevent="removeContactAdditional(index)"
        >
          <feather-icon
            icon="XIcon"
            class="mr-25"
          />
          <span>{{ $t('Eliminar') }}</span>
        </b-button>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
  },
  directives: {
    Ripple,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    view: {
      type: Boolean,
      default: () => false,
    },
    removeContactAdditional: {
      type: Function,
      required: true,
    },
  },
  computed:
  {
    ...mapGetters('crm_leads_form', ['form_sw095']),
  },
}
</script>
